import { versions } from './versions';

export const environment = {
  versions,
  production: false,
  hostUrl: '',
  apiUrl: 'https://dev-api.octopusmusicschool.com/api',
  frontendUrl: 'https://dev.octopusmusicschool.com/',
  persistUserSession: true,
  sentryKey: 'https://86acecc08fb74322b197c4225654ff74@sentry.thesunflowerlab.com/49',
  firebase: {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  swUpdate: false,
  swUpdateFooter: false,
  encryptionService: {
    enable: false,
    secretKey: 'BfBRoYxsRKGnx3DlPgnsmei7qVHTD62o',
    secretIV: 'cGsgbxxqbcYN6HZG'
  },
  compressAfterFileSize: 2
};
