import { Injectable } from '@angular/core';
import { Constants, MenuConfig, ROUTER_PATHS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class MenuConfigService {
  menuConfig = MenuConfig;
  menuConfigOriginal = JSON.parse(JSON.stringify(MenuConfig));
  menuChild = [
    {
      id: ROUTER_PATHS.dashboard.root,
      title: 'Dashboard',
      icon: Constants.staticImages.icons.dashboard,
      routerLink: ROUTER_PATHS.dashboard.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['dashboard'],
      subMenuOptions: [],
      authorities: [Constants.roles.ALL]
    },
    {
      id: ROUTER_PATHS.schedule.root,
      title: 'Schedule',
      icon: Constants.staticImages.icons.scheduleIcon,
      routerLink: ROUTER_PATHS.schedule.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['schedule'],
      subMenuOptions: [],
      authorities: [Constants.roles.ADMIN]
    },
    {
      id: ROUTER_PATHS.roomAndLocation.root,
      title: 'Room & Location Management',
      icon: Constants.staticImages.icons.roomIcon,
      routerLink: ROUTER_PATHS.roomAndLocation.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['Room & Location Management'],
      subMenuOptions: [],
      authorities: [Constants.roles.ADMIN]
    },
    {
      id: ROUTER_PATHS.members.root,
      title: 'Members',
      icon: Constants.staticImages.icons.memberIcon,
      routerLink: ROUTER_PATHS.members.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['members'],
      authorities: [Constants.roles.ADMIN],
      subMenuOptions: [
        {
          id: ROUTER_PATHS.members.instructors,
          title: 'Instructors',
          icon: '',
          routerLink: `${ROUTER_PATHS.members.root}/${ROUTER_PATHS.members.instructors}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.members.root,
          authorities: [Constants.roles.ADMIN]
        }
      ]
    },
    {
      id: ROUTER_PATHS.visits.root,
      title: 'Visits/Scheduling',
      icon: Constants.staticImages.icons.visitScheduling,
      routerLink: ROUTER_PATHS.visits.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['visits'],
      subMenuOptions: [],
      authorities: [Constants.roles.USER]
    },
    {
      id: ROUTER_PATHS.scheduleClasses.root,
      title: 'Schedule Classes',
      icon: Constants.staticImages.icons.scheduleIcon,
      routerLink: ROUTER_PATHS.scheduleClasses.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['schedule-classes'],
      authorities: [Constants.roles.USER],
      subMenuOptions: [
        {
          id: ROUTER_PATHS.scheduleClasses.groupClasses,
          title: 'Group Classes',
          icon: '',
          routerLink: `${ROUTER_PATHS.scheduleClasses.root}/${ROUTER_PATHS.scheduleClasses.groupClasses}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.scheduleClasses.root,
          authorities: [Constants.roles.USER]
        }
      ]
    },
    {
      id: ROUTER_PATHS.document.root,
      title: 'Document',
      icon: Constants.staticImages.icons.documentIcon,
      routerLink: ROUTER_PATHS.document.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['document'],
      subMenuOptions: [],
      authorities: [Constants.roles.USER]
    },
    {
      id: ROUTER_PATHS.referral.root,
      title: 'Referral',
      icon: Constants.staticImages.icons.referral,
      routerLink: ROUTER_PATHS.referral.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['referral'],
      subMenuOptions: [],
      authorities: [Constants.roles.USER]
    },
    {
      id: ROUTER_PATHS.help.root,
      title: 'Help',
      icon: Constants.staticImages.icons.help,
      routerLink: ROUTER_PATHS.help.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['help'],
      subMenuOptions: [],
      authorities: [Constants.roles.USER]
    },
    {
      id: ROUTER_PATHS.settings.root,
      title: 'Settings',
      icon: Constants.staticImages.icons.gearWhite,
      routerLink: ROUTER_PATHS.settings.root,
      isAccessible: true,
      parentRoute: null,
      isOpen: false,
      moduleKeys: ['settings'],
      authorities: [Constants.roles.ADMIN],
      subMenuOptions: [
        {
          id: ROUTER_PATHS.settings.plan,
          title: 'Plan',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.plan}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN]
        },
        {
          id: ROUTER_PATHS.settings.groupClass,
          title: 'Group Class',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.groupClass}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN]
        },
        {
          id: ROUTER_PATHS.settings.document,
          title: 'Document',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.document}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN]
        },
        {
          id: ROUTER_PATHS.settings.referral,
          title: 'Referral',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.referral}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN]
        },
        {
          id: ROUTER_PATHS.settings.help,
          title: 'Help',
          icon: '',
          routerLink: `${ROUTER_PATHS.settings.root}/${ROUTER_PATHS.settings.help}`,
          isAccessible: true,
          isOpen: false,
          parentRoute: ROUTER_PATHS.settings.root,
          authorities: [Constants.roles.ADMIN]
        }
      ]
    }
  ];

  constructor() {}

  modifyMenuConfig(menuConfig: Array<any>, role: string): void {
    for (const [index, menu] of menuConfig.entries()) {
      const selectedMenu = this.menuChild.find((menuItem) => menu.title === menuItem.title);
      menuConfig[index].subMenuOptions = selectedMenu?.subMenuOptions as any;
    }

    menuConfig = menuConfig.filter(
      (menuConfigItem) =>
        menuConfigItem.authorities.includes(role) || menuConfigItem.authorities.includes(Constants.roles.ALL)
    );

    this.menuConfig = menuConfig;
  }

  getMenuConfig() {
    return this.menuConfig;
  }

  resetMenuConfig(): void {
    this.menuConfig = this.menuConfigOriginal;
  }
}
