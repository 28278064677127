import { Validators } from '@angular/forms';
import { ActivatedRouteSnapshot } from '@angular/router';

export class CommonUtils {
  static getFullName(firstName: string | undefined, lastName: string | undefined): string {
    return `${firstName || ''} ${lastName || ''}`;
  }

  static getInitials(firstName: string | undefined, lastName: string | undefined): string {
    return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`;
  }

  static getInitialsUsingFullName(name: string | undefined): string {
    return `${name?.split(' ')[0].charAt(0) || ''}${name?.split(' ')[1].charAt(0) || ''}`;
  }

  static getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : 'OMS';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  static cleanObjectByRemovingKeysWithoutValue(obj: any): any {
    if (obj && typeof obj === 'object') {
      Object.keys(obj).forEach((key) => {
        if (!obj[key] || obj[key] === '') {
          delete obj[key];
        }
      });
    }
    return obj;
  }

  static calculateTimeDifference(startDate: Date, endDate: Date): number {
    const differenceInMs = endDate.getTime() - startDate.getTime();
    return Math.floor(differenceInMs / (1000 * 60));
  }
}
